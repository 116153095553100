@import 'styles/colors.scss';

.button {
    box-sizing: border-box;
    font-family: 'MarkBold';
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #b2b2b2;
    cursor: pointer;
    height: 40px;
    min-width: 135px;
}

.default {
    @extend .button;
    background-color: $white;
    color: $black;

    &:hover {
        background-color: $btnPrimaryHover;
        color: $white;
    }
}

.primary {
    @extend .button;
    background-color: $black;
    color: $white;

    &:hover {
        background-color: $btnPrimaryHover;
        color: $white;
    }
}

.disabled {
    @extend .button;
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
}
