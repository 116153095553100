@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.selectProductsContainer {
    .selectBox {
        width: 100%;
        max-width: 458px;
        height: 40px;
        padding: 0px 5px;
    }

    .formLabel {
        @include formLabel;
        display: block;
        font-size: 14px;
        padding-bottom: 10px;
    }

    .formHelperText {
        @include formHelperText;
    }
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;

    .checkboxLabel {
        font-family: 'MarkRegular';
        font-size: 14px;
        width: 200px;
    }
}
