@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.uploadFormDialogContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialogBox {
        min-width: 420px;
        max-width: 420px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dialogTitle{
            text-align: center;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            line-height: 28px;
            margin-bottom: 10px;
        }

        .dialogMess{
            text-align: center;
            font-size: 14px;
            color: #373D41;
            line-height: 20px;
        }
    }

    .dialogButtons {
        width: 100%;
        display: inline-flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 30px;

        .cancelButton {
            box-sizing: border-box;
            height: 40px;
            width: 184px;
            color: $black;
            font-family: 'MarkBold';
            font-size: 14px;
            line-height: 20px;
            background-color: $white;
            border: 1px solid #b2b2b2;
            cursor: pointer;

            &:hover {
                background-color: $btnPrimaryHover;
                color: $white;
            }
        }

        .confirmButton {
            height: 40px;
            width: 184px;
            background-color: $btnPrimaryActive;
            border: 1px solid #b2b2b2;
            color: $white;
            font-family: 'MarkBold';
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;

            &:hover {
                background-color: $btnPrimaryHover;
            }
        }
        .okayButton {
            height: 40px;
            width: 184px;
            background-color: #CE2E26;
            border: 1px solid #CE2E26;
            color: $white;
            font-family: 'MarkBold';
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;
            &:hover {
                background-color: #9b2d27;
            }
        }
    }
}
