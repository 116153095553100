@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.imageUploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .choose-file-container {
        background-color: $black;
        height: 200px;
        max-width: 200px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 17px;
        color: $white;
        position: relative;

        .choose-file-text {
            color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
        }

        &:hover {
            // background-color: $lightGrey;
            opacity: 0.7;
        }
    }
    .error {
        font-family: 'MarkRegular';
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0;
        color: $red;
    }

    .imageUploadButtons {
        @include buttonsRow;

        & button {
            border: none;
            background-color: $white;
            font-family: 'MarkBold';
            line-height: 20px;
            text-align: center;

            &.enabled {
                color: $black;
                cursor: pointer;
            }

            &.disabled {
                color: $lightGrey;
            }
        }
    }

    .supportedFile {
        color: $grey;
        font-family: 'MarkRegular';
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
    }
}
