@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.thumbnailUploadContainer {
    text-align: center;
    background-color: $black;
    width: 100%;
    height: 200px;

    .choose-file-container {
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: $white;
        position: relative;
        flex-direction: column;

        .choose-file-text {
            color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
        }

        &:hover {
            // background-color: $lightGrey;
            opacity: 0.7;
        }
    }
}

.imageUploadButtons {
    @include buttonsRow;
    justify-content: flex-start;
    padding-top: 10px;

    & button {
        border: none;
        background-color: $white;
        font-family: 'MarkBold';
        line-height: 20px;
        text-align: center;

        &.enabled {
            color: $black;
            cursor: pointer;
        }

        &.disabled {
            color: $lightGrey;
        }
    }
}
.thumbnailError {
    font-family: 'MarkRegular';
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0;
    color: $red;
}
.supportedFile {
    color: $grey;
    font-family: 'MarkRegular';
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}
