@import 'styles/colors.scss';

.trainerCardContainer {
    box-sizing: border-box;
    padding: 16px;
    background-color: $white;
    box-shadow: 0 2px 37px 0 #b2b2b269;
    min-width: 335px;
    height: 250px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    .list {
        margin: 0;
        padding-left: 10px;
        list-style: '- ';
        min-height: 100px;
    }

    .avatarContainer {
        width: 92px;
        height: 92px;
    }
}
