@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.accountDetailsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $white;
    box-shadow: 0 2px 37px 0 rgba(178, 178, 178, 0.41);
    padding: 60px 120px 60px 130px;

    .rowContainer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .MuiFormGroup-root {
            flex-direction: row;
        }

        .imageUploadContainer {
            flex: 1 1 auto;
        }

        .formAccountContainer {
            flex: 3 3 auto;

            .formCheckBoxLabel {
                @include formLabel;
                font-size: 14px;
            }

            .formInputLabel {
                @include formLabel;
                font-size: 14px;

                &.Mui-focused {
                    color: inherit;
                }
            }

            .formSelect {
                font-family: 'MarkRegular';
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
            }

            .MuiInput-underline:after {
                border-color: $black;
            }

            .MuiFormControlLabel-label {
                color: $black;
                font-family: 'MarkRegular';
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
            }

            .formHelperText {
                font-family: 'MarkRegular';
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0;

                &.error {
                    color: $red;
                }
            }
        }
    }

    .buttons {
        @include buttonsRow;
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
    }
}
