@import 'styles/colors.scss';

.paper {
    padding: 60px;

    .title {
        font-family: 'MarkRegular';
        font-size: 40px;
        line-height: 48px;
        color: $red;
        padding: 0 20px;
    }

    .content {
        padding: 20px 40px;

        .contentText {
            font-family: MarkBold;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 28px;
            text-align: center;
            color: $black;
        }
    }

    .actions {
        display: inline-flex;
        justify-content: center;
    }
}
