@import 'styles/colors.scss';

.formContainer {
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    width: 100%;

    .formBody {
        margin: 0 auto;
        padding: 50px 52px;
        max-width: 524px;
        background-color: $white;
        box-shadow: 0 33px 52px 0 #b2b2b27d;
        box-sizing: border-box;
        text-align: center;

        .formTitle {
            font-family: 'MarkBold';
            font-size: 18px;
            line-height: 24px;
        }

        .formText {
            text-align: left;
        }

        .formError {
            color: $red;
            font-family: 'MarkBold';
            font-size: 14px;
            font-weight: bold;
        }

        .formSuccess {
            color: $green;
            font-family: 'MarkBold';
            font-size: 14px;
            font-weight: bold;
        }

        .formControl {
            position: relative;
            display: block;
            margin-bottom: 19px;
            height: 52px;

            .formLabel {
                font-family: 'MarkBold';
                color: $black;
                font-size: 18px;
                font-weight: bold;
            }

            .formInput {
                width: 100%;
                height: 30px;
                color: $black;
                font-family: 'MarkRegular';
                font-size: 14px;
                line-height: 18px;
                bottom: 0;
                border: none;
                background-color: $white;
                box-shadow: inset 0 -1px 0 0 $lightGrey;
                padding: 0;
                line-height: 18px;

                &.error {
                    &:after {
                        border-bottom: 2px solid $red;
                    }
                }

                &:after {
                    border-bottom: 2px solid $black;
                }

                .formPasswordIcon {
                    color: $black;
                }
            }

            .formHelperText {
                font-family: 'MarkRegular';
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0;

                &.error {
                    color: $red;
                }
            }
        }

        .formButton {
            width: 100%;
            height: 40px;
            background-color: $btnPrimaryInActive;
            color: #ffffff;
            font-family: 'MarkBold';
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            border: 0;
            cursor: pointer;

            &.active {
                background-color: $btnPrimaryActive;

                &:hover {
                    background-color: $btnPrimaryHover;
                }
            }
        }
    }

    .formLink {
        margin-top: 30px;
        display: block;
        line-height: 20px;
        text-align: center;
        color: $black;

        &:hover {
            color: $red;
        }
    }
}
