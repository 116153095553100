@import 'styles/colors.scss';

.cardPlaceholderContainer {
    box-sizing: border-box;
    background-color: #f2f2f2;
    box-shadow: 0 2px 37px 0 #b2b2b269;
    padding: 80px 40px;

    .text {
        margin: 5px 0px;
        padding: 0;
        opacity: 0.7;

        &.emptySpace {
            color: $black;
            font-size: 16px;
            font-weight: bold;
        }

        &.content {
            font-size: 14px;
        }
    }
}
