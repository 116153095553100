@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.formControl {
    width: 100%;
    display: flex;
    flex: 1;
    margin-bottom: 15px !important;

    .formLabel {
        font-family: 'MarkBold';
        color: $black;
        font-size: 18px;
        font-weight: bold;
        position: relative;
        float: left;

        &.Mui-focused {
            color: inherit;
        }
    }

    .formInput {
        width: 100%;
        height: 30px;
        color: $black;
        font-family: 'MarkRegular';
        font-size: 14px;
        line-height: 18px;
        bottom: 0;
        border: none;
        background-color: $white;
        box-shadow: inset 0 -1px 0 0 $lightGrey;
        padding: 0;
        line-height: 18px;
        margin: 0;

        &.error {
            &:after {
                border-bottom: 2px solid $red;
            }
        }

        &:after {
            border-bottom: 2px solid $black;
        }
    }

    .formHelperText {
        font-family: 'MarkRegular';
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0;

        &.error {
            color: $red;
        }
    }
}
