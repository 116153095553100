@import 'styles/colors.scss';

.videoCardContainer {
    box-sizing: border-box;
    padding: 16px;
    background-color: $white;
    box-shadow: 0 2px 37px 0 #b2b2b269;
    height: 192px;
    width: 521px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    .videoScreenContainer {
        width: 285px;
        height: 160px;
    }

    .videoText {
        margin: 0;
        padding: 0;

        &.title,
        &.trainerName {
            padding: 4px 0px;
            font-size: 16px;
        }

        &.trainerName {
            color: $grey;
        }
    }
}
