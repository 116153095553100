@import 'styles/colors.scss';

.tabsContainer {
    .tab {
        text-transform: none;
        color: $black !important;
        font-family: 'MarkBold' !important;
        font-size: 18px !important;
        line-height: 24px !important;
    }

    .MuiTabs-indicator {
        display: flex;
        justify-content: center;
        background-color: $red;
        transition: none;
    }
}
