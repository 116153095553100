@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.radioButtonsControl {
    .radioGroupLabel {
        @include formLabel;
        font-size: 14px;

        &.Mui-focused {
            color: inherit;
        }
    }
}
