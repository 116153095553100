@import 'styles/colors.scss';

.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.headline {
    color: $black;
    margin: 88px 0 0 0;
}

.subtitle {
    font-size: 18px;
    line-height: 24px;
    color: $black;
    margin: 16px 0 0 0;
}

.storeButtons {
    display: flex;
    align-items: center;
    margin-top: 48px;
}
