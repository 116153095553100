.thumbnail {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;

    &.contain {
        object-fit: contain;
    }
}
