@import 'styles/colors.scss';

.copyRightContainer {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;

    .text {
        color: $grey;
        font-family: 'MarkRegular';
        font-size: 14px;
        margin-bottom: 50px;
    }

    .copyright {
        color: $grey;
        font-family: 'MarkRegular';
        font-size: 14px;
    }
}
