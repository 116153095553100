@import 'styles/colors.scss';

.text {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
}

.trainingDescriptionInput {
    resize: none;
    height: 206px;
    max-width: 389px;
    border: 1px solid $grey;
    padding: 10px;
    font-size: 14px;
    font-family: 'MarkRegular';
}
