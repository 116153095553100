@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.certificateContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $white;
    box-shadow: 0 2px 37px 0 rgba(178, 178, 178, 0.41);
    padding: 60px 120px 60px 130px;

    .formCheckBoxLabel {
        @include formLabel;
        font-size: 14px;

        &.Mui-focused {
            color: inherit;
        }
    }

    .MuiFormControlLabel-label {
        @include formLabel;
        font-family: 'MarkRegular';
        font-size: 14px;
    }

    .formHelperText {
        @include formHelperText;
    }

    .buttons {
        @include buttonsRow;
        margin-top: 50px;
    }
}
