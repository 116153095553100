@import 'styles/colors.scss';

.expertise {
    font-size: 16px;
    line-height: 20px;
}

.fullName {
    font-family: MarkBold;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
}

.inactive {
    opacity: 0.3;
}
