@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.uploadTrainingFormDialogContainer {
    margin: 40px;

    .dialogForm {
        min-width: 420px;
        max-width: 420px;
    }

    .formHelperText {
        @include formHelperText;
    }

    .selectTrainerContainer {
        .selectBox {
            width: 100%;
            height: 40px;
            padding: 0px 5px;
        }

        .formLabel {
            @include formLabel;
            display: block;
            font-size: 14px;
        }

        .required:after {
            content: "*";
            margin-left: 1px;
        }
    }

    .dialogButtons {
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-top: 50px;

        .cancelButton {
            box-sizing: border-box;
            height: 40px;
            width: 184px;
            color: $black;
            font-family: 'MarkBold';
            font-size: 14px;
            line-height: 20px;
            background-color: $white;
            border: 1px solid #b2b2b2;
            cursor: pointer;

            &:hover {
                background-color: $btnPrimaryHover;
                color: $white;
            }
        }

        .confirmButton {
            height: 40px;
            width: 184px;
            background-color: $btnPrimaryActive;
            border: 1px solid #b2b2b2;
            color: $white;
            font-family: 'MarkBold';
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;

            &:hover {
                background-color: $btnPrimaryHover;
            }
        }
    }
}
