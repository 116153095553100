@import 'styles/colors.scss';

.text {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
}

.descriptionAgendaInput {
    resize: none;
    height: 206px;
    max-width: 389px;
    border: 1px solid $grey;
    padding: 10px;
    font-size: 14px;
    font-family: 'MarkRegular';
    margin-right: 30px;
}

.agendaButton {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    flex-wrap: wrap;
    justify-content: center;
    .iconEducation {
        color: $red;
    }

    .buttonText {
        color: $red;
        font-family: 'MarkBold';
    }
}
.itemButton {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    flex-wrap: wrap;
    .iconEducation {
        color: $red;
    }
    button {
        padding: 0 12px;
    }
    .buttonText {
        color: $red;
        font-family: 'MarkBold';
    }
}

@media (max-width: 1024px) {
    .descriptionAgendaInput {
        margin-right: 0;
    }
}
