@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.text {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
}

.descriptionInput {
    resize: none;
    width: 95%;
    height: 206px;
    border: 1px solid $grey;
    padding: 10px;
    font-size: 14px;
    font-family: 'MarkRegular';
}

.datePicker {
    font-family: 'MarkRegular';
    font-size: 14px;
    border: 1px solid #b2b2b2;
    background-color: #ffffff;
    border-radius: 0;
}

.react-datepicker__header {
    background-color: $white;
    border-bottom: none;
}

.react-datepicker__day-name {
    color: $red;
}

.react-datepicker__current-month {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before {
    border-color: $red;
    height: 6px;
    width: 6px;
    border-width: 2px 2px 0 0;
}

.react-datepicker__day:hover {
    border-radius: 14px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
    background-color: $red;
    border-radius: 14px;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    overflow-y: hidden;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    overflow-y: scroll;
}

.text-error::before {
    font-weight: bold;
    color: gray;
    content: 'Taken ';
}
.time-picker {
    border-radius: 0 !important;
    width: 180px;
    height: 40px;
    justify-content: space-between !important;
}
.text-field {
    fieldset {
        border-radius: 0;
    }
}
.react-datepicker__header--time {
    display: none;
}
li.react-datepicker__time-list-item--selected {
    background-color: #ce2e26 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
}
.react-datepicker__day--selected {
    background-color: #ce2e26;
}
.react-datepicker__day--selected:hover {
    background-color: #ce2e26;
}
.react-datepicker__triangle {
    display: none !important;
}

.formHelperText {
    @include formHelperText;
    max-width: 100%;
}
