@import 'styles/colors.scss';

.trainingTitle {
    font-family: MarkBold;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
}
.trainingType {
    box-sizing: border-box;
    height: 22px;
    width: 124px;
    border: 1px solid #CE2E26;
    background-color: rgba(206,46,38,0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        color: #CE2E26;
        font-size: 12px;
        font-weight: bold;
      }
  }
.trainingDetails {
    display: flex;
    flex-wrap: wrap;
}
