@import 'styles/colors';

@font-face {
    font-family: 'MarkRegular';
    src: local('Mark Regular'),
        url(./assets/fonts/MarkRegular.otf) format('opentype');
}

@font-face {
    font-family: 'MarkLight';
    src: local('Mark Light'),
        url(./assets/fonts/MarkLight.otf) format('opentype');
}

@font-face {
    font-family: 'MarkBold';
    src: local('Mark Bold'), url(./assets/fonts/MarkBold.otf) format('opentype');
}

body {
    font-family: 'MarkRegular';
    font-size: 14px;
    line-height: 20px;
    color: $black;
    margin: 0;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

h1 {
    font-family: 'MarkRegular';
    font-size: 40px;
    line-height: 48px;
    color: $red;
}

h2 {
    font-family: 'MarkRegular';
    font-size: 32px;
    line-height: 40px;
    color: $red;
}

h3 {
    font-family: 'MarkBold';
    font-size: 22px;
    line-height: 32px;
    color: $red;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input {
    font-family: 'MarkLight' !important;
}

.p-10 {
    padding: 10px;
}

.right {
    text-align: right;
}

.gray-text {
    color: $grey;
}

.with-pointer {
    cursor: pointer;
}

.no-pointer {
    cursor: not-allowed;
}

.light-placeholder {
    color: $lightGrey;
    font-family: 'MarkRegular';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: none;
}

.unlinkify {
    &,
    &:hover,
    &:active,
    &:visited {
        text-decoration: none;
        color: $black;
    }
}

.MuiDialog-paperWidthSm {
    max-width: 740px !important;
}
