@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.actionButton {
    display: inline-flex;
    justify-content: flex-end;
    gap: 10px;

    border: none;
    background-color: $white;
    font-family: 'MarkBold';
    line-height: 20px;
    text-align: center;
    cursor: pointer;
}
