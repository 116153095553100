// Definition of colors

// colors
$black: #000000;
$red: #ce2e26;
$green: #249a15;
$grey: #706f6f;
$lightGrey: #b2b2b2;
$placholderGrey: #8b999f;

$white: #ffffff;

// buttons
$btnPrimaryHover: $red;
$btnPrimaryActive: $black;
$btnPrimaryInActive: $grey;

//navigation
$menuBackground: #f2f2f2;
$divider: #aeaeae;
$poweredBy: #a7a9ac;

//image upload
$imageBackground: #f2f2f2;
