@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.trainerDetailsContainer {
    display: flex;
    flex-wrap: wrap;

    .avatar {
        width: 200px;
        height: 200px;
    }

    .label {
        @include formLabel;
        font-size: 14px;
        margin: 0;
        padding: 0;
        min-width: 200px;
    }

    .value {
        margin: 0;
        padding: 0;
    }

    .list {
        margin: 0;
        padding-inline-start: 16px;
    }

    .socialIcon {
        width: 20px;
        height: 20px;
        margin-right: 20px;
    }
}
