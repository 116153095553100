@import 'styles/colors.scss';

.livestreamStrikeTitle {
    font-family: 'MarkBold';
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-decoration: line-through 1px;
}

.livestreamTitle {
    font-family: 'MarkBold';
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
}

.date {
    font-family: 'MarkBold';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    margin-right: 10px;
}

.numberOfParticipants {
    color: $grey;
    font-family: 'MarkLight';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}

.canceled,
.live,
.finished {
    font-family: 'MarkBold';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
}

.canceled {
    color: $red;
}

.live {
    color: $red;
    width: 100%;
}

.finished {
    color: $grey;
    width: 100%;
}
