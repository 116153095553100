@import 'styles/colors.scss';

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 48%;
}

.column:first-child {
    margin-right: 40px;
}

.bold {
    font-family: 'MarkBold';
}

.light {
    font-family: 'MarkLight';
    font-size: 12px;
}

.uploadLivestreamContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $black;

    p {
        color: $white;
        padding: 0;
        margin: 0;

        &.error {
            color: $red;
        }
    }
}

.error {
    color: $red;
}

.uploadVideoTabContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
}

.videoFile {
    height: 258px;
}

.thumbnailFile {
    height: 101px;
    width: 179px;
}

.thumbnailFileDetails > p {
    padding: 0;
    margin: 0;
    font-size: 12px;
}

.chooseFileLink {
    color: $white;
    text-decoration: underline;
    text-decoration-color: $white;
    cursor: pointer;
}

.actionButton {
    border: none;
    background-color: $white;
    font-family: 'MarkBold';
    line-height: 20px;
    text-align: center;
    color: $black;
    cursor: pointer;

    &.disabled {
        color: $lightGrey;
    }
}

@media (max-width: 1024px) {
    .uploadVideoTabContainer {
        flex-direction: column;
    }

    .column {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
    }

    .column:first-child {
        margin-right: 0;
    }
}
