@import 'styles/colors.scss';

.menuContainer {
    width: 30%;
    min-width: 300px;
    background-color: $menuBackground;
    box-shadow: 0 2px 37px 0 #b2b2b269;
    padding: 60px 80px 60px 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .menu {
        float: right;
        max-width: 300px;
        display: flex;
        flex-direction: column;

        .userSectionContainer {
            display: flex;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 40px;

            .imageContainer {
                width: 70px;
                height: 70px;
                margin-right: 15px;

                &.white {
                    background: $white;
                }
            }

            .textContainer {
                display: flex;
                flex-direction: column;

                .userName {
                    color: $black;
                    font-family: 'MarkBold';
                    font-size: 18px;
                    line-height: 24px;
                }

                .userRole {
                    color: $grey;
                    font-family: 'MarkRegular';
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .menuSectionContainer {
            margin-bottom: 28px;
            .menuSection {
                display: flex;
                align-items: center;
                margin-bottom: 9px;

                .menuSectionText {
                    color: $black;
                    font-family: 'MarkBold';
                    font-size: 16px;
                    line-height: 24px;
                    margin-left: 10px;
                }
            }

            .menuLinkContainer {
                margin-bottom: 4px;
                .menuLink {
                    color: $black;
                    font-family: 'MarkRegular';
                    font-size: 16px;
                    line-height: 24px;
                    text-decoration: none;
                    margin-left: 31px;

                    &:hover {
                        text-decoration: none;
                        color: $red;
                    }
                }

                .active {
                    text-decoration: none;
                    color: $red;
                }
            }
        }

        .dividerSectionContainer {
            height: 1px;
            background-color: $divider;
            margin-bottom: 18px;
        }

        .logoutSectionContainer {
            display: flex;
            align-items: center;

            .logout {
                color: $black;
                font-family: 'MarkBold';
                font-size: 16px;
                line-height: 24px;
                text-decoration: none;
                margin-left: 10px;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    color: $red;
                }
            }
        }
    }

    .poweredByContainer {
        margin-top: 100px;

        .text {
            display: flex;
            align-items: center;
            color: $poweredBy;
            font-family: 'MarkRegular';
            font-size: 14px;
            line-height: 18px;
            text-align: center;
        }
    }
}
