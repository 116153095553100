@import 'styles/colors.scss';

@mixin formControl {
    position: relative;
    display: block;
    margin-bottom: 19px;
    height: 52px;
}

@mixin formLabel {
    font-family: 'MarkBold';
    color: $black;
    font-size: 18px;
    font-weight: bold;
}

@mixin formInput {
    width: 100%;
    height: 30px;
    color: $black;
    font-family: 'MarkRegular';
    font-size: 14px;
    line-height: 18px;
    bottom: 0;
    border: none;
    background-color: $white;
    box-shadow: inset 0 -1px 0 0 $lightGrey;
    padding: 0;
    line-height: 18px;
    &.error {
        &:after {
            border-bottom: 2px solid $red;
        }
    }

    &:after {
        border-bottom: 2px solid $black;
    }
}

@mixin formHelperText {
    font-family: 'MarkRegular';
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0;

    &.error {
        color: $red;
    }
}

@mixin buttonsRow {
    display: inline-flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

@mixin buttonColor(
    $backgroundColor: $btnPrimaryActive,
    $textColor: $white,
    $hoverBackgroundColor: $btnPrimaryHover,
    $hoverTextColor: $white,
    $enableHover: true
) {
    background-color: $backgroundColor;
    color: $textColor;

    @if $enableHover {
        &:hover {
            background-color: $hoverBackgroundColor;
            color: $hoverTextColor;
        }
    }
}
