@import 'styles/colors.scss';
@import 'styles/mixins.scss';

.educationContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $white;
    box-shadow: 0 2px 37px 0 rgba(178, 178, 178, 0.41);
    padding: 60px 120px 60px 130px;

    .rowContainer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .MuiFormGroup-root {
            flex-direction: row;
        }

        .formCheckBoxLabel {
            @include formLabel;
            font-size: 14px;

            &.Mui-focused {
                color: inherit;
            }
        }

        .MuiFormControlLabel-label {
            @include formLabel;
            font-family: 'MarkRegular';
            font-size: 14px;
        }

        .formHelperText {
            @include formHelperText;
        }

        .educationButton {
            display: flex;
            align-items: center;
            margin-bottom: 9px;
            flex-wrap: wrap;

            .iconEducation {
                color: $red;
            }

            .buttonText {
                color: $red;
                font-family: 'MarkBold';
            }
        }
    }

    .buttons {
        @include buttonsRow;
        margin-top: 50px;
    }
}
